/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isFunction from 'lodash/isFunction';
import '../../../styles/main.scss';
import { CaptureExtra } from '@lib/pages/v2/CaptureExtra';

import { Questionnaire } from '@containers';

import { isShownPrivacy, isWebRTCSupported } from '@lib/Utils';
import { cleanAppCookies } from '@lib/utils/appCookies';
import APIs from '@services/APIs';
import { localizedString } from '@languages';
import { PrivacyScreen } from '@lib/pages/v2/PrivacyScreen';
import { FLOW_V2_FORCE_ALTERNATE_FLOW, IMMEDIATE_REDIRECT } from '@spotMobileConfig';
import LoadingBar from '@lib/components/v2/LoadingBar';
import FaceScan from '@lib/containers/FaceScan';
import Success from '@lib/containers/Success';
import Language from '@lib/components/v2/Language';
import AlternateFlow from '@lib/containers/AlternateFlow';
import { compose } from 'redux';
import { withOrientationCheck } from '@lib/components/v2/App/OrientationCheck';
import { withTimeoutCheck } from '@lib/components/v2/App/TimeoutCheck';
import { withDeviceCheck } from '@lib/components/v2/App/DeviceCheck';
import { withDeviceMotionDetection } from '@lib/components/v2/App/DeviceMotionDetection';
import { withFlowTypeProvider } from '@lib/components/v2/App/FlowTypeProvider';
import { withFullNameReviewDetails } from '@lib/components/v2/App/FullNameReviewProvider';
import { Error500, InternetCut } from '@lib/components/v2/errors';
import { FLOW_V2_INCLUDE_WELCOME_SCREEN } from '@js/constants/appConfig';
import { Capture, Datacheck, MoreInfo, ProofOfAddress, VerifyDetails, Welcome } from '..';
import { LoadingSpinner } from '../../components';
import { SCREENS } from './Screens';

class App extends Component {
  constructor(props) {
    super(props);

    const { appConfig, flowType, questionnaire } = this.props;
    const isLiveOnly = flowType === 'LIVE_ONLY';
    const { disablePrimaryIdDocCapture = false, forcePoaCapture = false } = appConfig;

    this.screensSequence = [
      { name: SCREENS.QUESTIONNAIRE_BEFORE_PRIVACY, enabled: questionnaire?.position === 'BEFORE' },
      { name: SCREENS.WELCOME, enabled: appConfig[FLOW_V2_INCLUDE_WELCOME_SCREEN] },
      { name: SCREENS.PRIVACY, enabled: isShownPrivacy(flowType) },
      { name: SCREENS.QUESTIONNAIRE_AFTER_PRIVACY, enabled: questionnaire?.position === 'AFTER' },
      { name: SCREENS.CAPTURE, enabled: !isLiveOnly && !disablePrimaryIdDocCapture },
      {
        name: SCREENS.VERIFY_DETAILS,
        enabled: () => {
          const { disableVerifyDetailsScreen } = this.state;
          return !disableVerifyDetailsScreen && !isLiveOnly && !disablePrimaryIdDocCapture;
        }
      },
      {
        name: SCREENS.DATA_CHECK_ON_CONFIRM,
        enabled: () => {
          const { dataCheckOnConfirm } = this.state;
          return dataCheckOnConfirm && !isLiveOnly && !disablePrimaryIdDocCapture;
        }
      },
      {
        name: SCREENS.PROOF_OF_ADDRESS,
        enabled: () => {
          const { poaEnabled } = this.state;
          return (poaEnabled && !isLiveOnly) || forcePoaCapture;
        }
      },
      { name: SCREENS.MORE_INFO, enabled: appConfig.showMoreInfoScreen },
      {
        name: SCREENS.FACE_SCAN,
        enabled: () => {
          const { hasLivenessScreen } = this.props;
          const { webrtc } = this.state;
          return hasLivenessScreen && !FLOW_V2_FORCE_ALTERNATE_FLOW && webrtc.status;
        }
      },
      {
        name: SCREENS.ALTERNATE_FLOW,
        enabled: () => {
          const { hasLivenessScreen } = this.props;
          const { selfieFR, webrtc } = this.state;
          return hasLivenessScreen && (FLOW_V2_FORCE_ALTERNATE_FLOW || selfieFR || !webrtc.status);
        }
      },
      {
        name: SCREENS.CAPTURE_EXTRA,
        enabled: appConfig.FLOW_V2_ADDITIONAL_DOC
      }
    ];

    this.state = {
      currentScreenName: null,
      idType: 'PASSPORT',
      tokenId: '',
      geolocation: '',
      error: null,
      completed: false,
      isProcessing: false,
      isUploading: false,
      uploadBar: 0,
      webrtc: {
        todo: null,
        status: true
      },
      redirect: false,
      verify: false,
      verifyDetails: {},
      showLanguageSelectionPrompt: false,
      selfieFR: false,
      dataCheckOnConfirm: false,
      documentId: null,
      selectedDiffId: null,
      isFlowV2DiffId: false,
      captureVisa: false
    };

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  async componentDidMount() {
    const { verify } = this.props;

    this.handleNextStep();

    this.setState({ verify });

    const { status, todo } = await isWebRTCSupported();
    this.setState({ webrtc: { todo, status } });

    // Detect if internet cut
    window.addEventListener('offline', () =>
      this.setState({
        error: {
          component: InternetCut,
          props: {
            onTryAgain: () => document.location.reload()
          }
        }
      })
    );
    // Detect if internet re connected
    window.addEventListener('online', () => {
      this.setState({
        error: {
          component: InternetCut,
          props: {
            onTryAgain: () => this.setState({ error: null })
          }
        }
      });
    });
  }

  /**
   * Go to next step.
   *
   * @param {Object} state
   * @return {Void}
   */
  handleNextStep({ additionalInfo, ...state } = {}) {
    if (state.isDataOnlyCompleted) {
      this.finishFlow();
      return;
    }

    if ('captureVisa' in state && state.captureVisa) {
      this.setState({
        currentScreenName: SCREENS.CAPTURE,
        captureVisa: true,
        isFlowV2DiffId: true,
        selectedDiffId: {
          type: 'VISA'
        }
      });
      return;
    }

    this.setState({ ...state }, () => {
      const currentScreenIndex = this.getCurrentScreenIndex();

      const nextScreen = this.screensSequence
        .slice(currentScreenIndex + 1)
        .find(this.firstEnabledScreenPredicate);

      if (nextScreen) {
        this.setState({ currentScreenName: nextScreen.name });
      } else {
        this.finishFlow({ additionalInfo });
      }
    });
  }

  /**
   * Go back to pev step.
   *
   * @return {Void}
   */
  handleGoBack() {
    const currentScreenIndex = this.getCurrentScreenIndex();

    const previousScreen = this.screensSequence
      .slice(0, currentScreenIndex)
      .findLast(this.firstEnabledScreenPredicate);

    if (previousScreen) {
      this.setState({ currentScreenName: previousScreen.name });

      if (previousScreen.name === SCREENS.VERIFY_DETAILS) {
        this.setState({
          verifyDetails: {},
          selfieFR: false,
          dataCheckOnConfirm: false,
          poaEnabled: false,
          documentId: null,
          selectedDiffId: null,
          isFlowV2DiffId: false
        });
      }
    }
  }

  handleComplete = ({ sessionId, lr }) => {
    /**
     * Upload face scan video.
     */
    const data = {
      id: sessionId,
      lr,
      actions: `Smile, Turn head ${lr ? 'left' : 'right'}`,
      isEngineV4: true,
      success: 1
    };

    this.setState({ isProcessing: true });

    APIs.uploadVideo(data, {})
      .then(({ status, msg: error, action = null }) => {
        if (status !== 'success') {
          if (action === 'ALTERNATE') {
            this.handleNextStep({ selfieFR: true });
            return;
          }
          console.error('video upload failed', { data, error });
          throw new Error(error);
        }

        this.setState({ isProcessing: false });
        this.handleNextStep({ selfieFR: false });
      })
      .catch((err) => {
        console.error(err);
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              this.setState({ error: null });
            }
          }
        };
        this.setState({ error, isProcessing: false });
      });
  };

  getCurrentScreenIndex = () => {
    const { currentScreenName } = this.state;
    return this.screensSequence.findIndex(
      (sequenceItem) => sequenceItem.name === currentScreenName
    );
  };

  // eslint-disable-next-line class-methods-use-this
  firstEnabledScreenPredicate = (sequenceItem) => {
    if (isFunction(sequenceItem.enabled)) {
      return sequenceItem.enabled();
    }
    return sequenceItem.enabled;
  };

  handleRedirection = ({ redirectTo = false, timeoutInSeconds = 5 }) => {
    if (redirectTo) {
      if (IMMEDIATE_REDIRECT) {
        APIs.return();
        return;
      }
      setTimeout(() => {
        APIs.return();
      }, timeoutInSeconds * 1000);
      this.setState({ isProcessing: false, isUploading: false, redirect: true, completed: true });
    } else {
      this.setState({ isProcessing: false, isUploading: false, completed: true });
    }
  };

  finishFlow = ({ additionalInfo = false } = {}) => {
    const { geolocation } = this.state;
    this.setState({ isProcessing: true });
    cleanAppCookies();
    APIs.markCompleted({ additionalInfo, geolocation }).then(({ redirectTo = null }) => {
      this.handleRedirection({ redirectTo });
    });
  };

  updateDocId = (documentId) => {
    this.setState({
      documentId
    });
  };

  handleChooseDiffId = (newDoc) => {
    this.setState({
      currentScreenName: SCREENS.CAPTURE,
      selectedDiffId: newDoc,
      isFlowV2DiffId: true
    });
  };

  /**
   * Render the component's.
   *
   * @return {ReactElement}
   */
  render() {
    const {
      idType,
      tokenId,
      error,
      completed,
      isProcessing,
      isUploading,
      uploadBar,
      geolocation,
      verify,
      nextCapture,
      showLanguageSelectionPrompt,
      disabledVerifyDetailsFields,
      disabledFields,
      verifyDetails,
      useIdNumber,
      personNameFieldsOrder,
      isOldTurkishDL,
      documentId,
      selectedDiffId,
      isFlowV2DiffId,
      redirect,
      captureVisa,
      currentScreenName
    } = this.state;

    const { component: Error, props: errorProps } = error || {};

    const { flowType, questionnaire } = this.props;
    const isLiveOnly = flowType === 'LIVE_ONLY';

    if (completed) {
      return <Success redirect={redirect} />;
    }

    if (showLanguageSelectionPrompt) {
      return <Language onGoback={() => this.setState({ showLanguageSelectionPrompt: false })} />;
    }

    return (
      <div>
        {Error && <Error {...errorProps} />}

        {/* Pages */}
        <div inert={error ? 'true' : undefined}>
          {[SCREENS.QUESTIONNAIRE_BEFORE_PRIVACY, SCREENS.QUESTIONNAIRE_AFTER_PRIVACY].includes(
            currentScreenName
          ) && (
            <Questionnaire
              questionnaire={questionnaire}
              onGoBack={this.handleGoBack}
              onComplete={() => this.handleNextStep()}
              enableExitButton={currentScreenName === SCREENS.QUESTIONNAIRE_AFTER_PRIVACY}
            />
          )}
          {currentScreenName === SCREENS.WELCOME && (
            <Welcome onStart={() => this.handleNextStep()} />
          )}
          {currentScreenName === SCREENS.PRIVACY && (
            <PrivacyScreen
              flowType={flowType}
              onAccept={() => this.handleNextStep()}
              onSelectLanguage={() => this.setState({ showLanguageSelectionPrompt: true })}
            />
          )}

          {currentScreenName === SCREENS.CAPTURE && (
            <Capture
              captureVisa={captureVisa}
              isFlowV2DiffId={isFlowV2DiffId}
              selectedDiffId={selectedDiffId}
              onGoBack={this.handleGoBack}
              onNextStep={(stateChanges) => this.handleNextStep(stateChanges)} // TODO: This legacy code is an antipattern. Change on explicit param were made here in order to make it more obviuos that is bad thing.
              onGeoLocation={(geolocation) => this.setState({ geolocation })}
              nextCapture={nextCapture}
              setDocId={this.updateDocId}
              flowType={flowType}
              verify={verify}
            />
          )}

          {currentScreenName === SCREENS.VERIFY_DETAILS && (
            <VerifyDetails
              verifyDetails={verifyDetails}
              useIdNumber={useIdNumber}
              personNameFieldsOrder={personNameFieldsOrder}
              isOldTurkishDL={isOldTurkishDL}
              isFlowV2DiffId={isFlowV2DiffId}
              onChooseDiffId={(newDoc) => this.handleChooseDiffId(newDoc)}
              idType={idType}
              token={tokenId}
              verify={verify}
              flowType={flowType}
              location={geolocation}
              onNextStep={({ dataCheckOnConfirm, poaEnabled, captureVisa } = {}) =>
                this.handleNextStep({ dataCheckOnConfirm, poaEnabled, captureVisa })
              }
              retake={() => this.handleGoBack()}
              onGoBack={() => this.handleGoBack()}
              disabledFields={disabledVerifyDetailsFields}
            />
          )}

          {currentScreenName === SCREENS.DATA_CHECK_ON_CONFIRM && (
            <Datacheck
              isFlowV2DiffId={isFlowV2DiffId}
              documentId={documentId}
              onChooseDiffId={this.handleChooseDiffId}
              onNextStep={({ poaEnabled } = {}) =>
                this.handleNextStep(poaEnabled ? { poaEnabled } : {})
              }
              disabledFields={disabledFields}
            />
          )}

          {currentScreenName === SCREENS.PROOF_OF_ADDRESS && (
            <ProofOfAddress
              onBack={() => this.handleGoBack()}
              onNextStep={() => this.handleNextStep()}
            />
          )}

          {currentScreenName === SCREENS.MORE_INFO && (
            <MoreInfo
              token={tokenId}
              onNextStep={() => this.handleNextStep()}
              onGoBack={() => this.handleGoBack()}
            />
          )}

          {currentScreenName === SCREENS.FACE_SCAN && (
            <FaceScan
              idType={idType}
              tokenId={tokenId}
              onComplete={this.handleComplete}
              onGoBack={isLiveOnly ? () => {} : () => this.handleGoBack()}
              onNextStep={({ selfieFR = false } = {}) => this.handleNextStep({ selfieFR })}
              onSelfie={() => this.handleNextStep({ selfieFR: true })}
              location={geolocation}
            />
          )}
          {currentScreenName === SCREENS.ALTERNATE_FLOW && (
            <AlternateFlow
              onNextStep={() => this.handleNextStep()}
              onGoBack={isLiveOnly ? () => {} : () => this.handleGoBack()}
            />
          )}

          {currentScreenName === SCREENS.CAPTURE_EXTRA && (
            <CaptureExtra
              onNextStep={({ additionalInfo = false } = {}) =>
                this.handleNextStep({ additionalInfo })
              }
            />
          )}
        </div>

        {/* Loadings */}
        {isUploading && <LoadingBar heading={localizedString('uploading')} width={uploadBar} />}
        {isProcessing && (
          <LoadingSpinner subtitle="" heading={localizedString('verifyingYourIdentity')} />
        )}
      </div>
    );
  }
}

App.propTypes = {
  verify: PropTypes.bool,
  appConfig: PropTypes.object,
  idDetails: PropTypes.object,
  questionnaire: PropTypes.object,
  flowType: PropTypes.string,
  hasLivenessScreen: PropTypes.bool,
  checkDeviceMotion: PropTypes.func
};

export default compose(
  withDeviceMotionDetection,
  withFlowTypeProvider,
  withOrientationCheck,
  withTimeoutCheck,
  withDeviceCheck({ checkWebRTC: true }),
  withFullNameReviewDetails,
  connect(mapStateToProps)
)(App);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, appConfig }) {
  return {
    appConfig,
    idDetails: information.idDetails
  };
}
